"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
class LogcalculatorCtrl {
    constructor($scope, $locale, $filter, actionService) {
        this.$scope = $scope;
        this.$locale = $locale;
        this.$filter = $filter;
        this.actionService = actionService;
        this.kind = 'log';
    }
    $onInit() {
    }
    _getBaseLog(x, y) {
        return Math.log(x) / Math.log(y);
    }
    _customFormatted(x) {
        try {
            return Intl.NumberFormat(this.$locale.id, {
                style: 'decimal',
                useGrouping: true,
                //@ts-ignore
                trailingZeroDisplay: 'stripIfInteger',
                maximumFractionDigits: 10
            }).format(x);
        }
        catch (e) {
            return this.$filter('myNumber')(x, 10);
        }
    }
    submitForm($form) {
        if ($form.$valid) {
            if (this.kind == 'log' && (this.logOf != undefined) && (this.base != undefined)) {
                const value = this._getBaseLog(this.logOf, this.base);
                this.result = {
                    kind: this.kind,
                    logOf: this.logOf,
                    base: this.base,
                    value: value,
                    valueFormatted: this._customFormatted(value),
                };
            }
            else if (this.kind == 'antilog' && (this.logValue != undefined) && (this.base != undefined)) {
                const value = Math.pow(this.base, this.logValue);
                this.result = {
                    kind: this.kind,
                    logValue: this.logValue,
                    base: this.base,
                    value: value,
                    valueFormatted: this._customFormatted(value),
                };
            }
            else {
                this.result = undefined;
            }
        }
    }
    setKind(kind) {
        if (this.logForm)
            this.clear(this.logForm);
        this.kind = kind;
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.result = undefined;
        this.logOf = undefined;
        this.logValue = undefined;
        this.base = undefined;
    }
    copy(id, value) {
        this.actionService.copyFactory(document.getElementById(id), value);
    }
}
LogcalculatorCtrl.$inject = ['$scope', '$locale', '$filter', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameLogcalc', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: LogcalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('logcalculator/');
    }]);
